<template>
  <v-list-group :value="false" exact color="">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="subtitle-2">{{ item.title }}</v-list-item-title>
    </template>
    <menu-item-default
      v-for="(subItem, index) in item.children"
      :key="subItem.id"
      :v-testid="`sub-${item.id}-${index}`"
      :item="subItem"
      v-on="$listeners"
    />
  </v-list-group>
</template>

<script>
import MenuItemDefault from './MenuItemDefault';
export default {
  name: 'MenuItemGroup',
  components: {
    MenuItemDefault
  },
  props: {
    level: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
