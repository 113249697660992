import { useMap } from '@/compositions/map';
import { useLazyQuery, useMutation, useResult } from '@vue/apollo-composable';

import { computed, onMounted, ref, watch } from '@vue/composition-api';

import schema from '@/modules/account/schema.graphql';
import { mediaClient } from '@/provider';
import { imageService, schemasService } from '@/modules/common/api';
import { baseLayersDefinition } from '@/compositions/map/base-layer';

const useGeneralSettings = (props, context) => {
  const { getCenter, setZoom, mapZoomIn, mapZoomOut, getZoom } = useMap();

  const schemaMonitorProfile = useLazyQuery(schema.schemaMonitorProfile, null, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  });

  schemaMonitorProfile.onResult(({ data }) => {
    data.schemata[0].schemaProperties.forEach(item => {
      let target = programSettings.value.find(
        itemInner => item.key === itemInner.key
      );

      if (target) {
        target.defaultValue = item.defaultValue;

        if (item.key === 'programDefaultPosition') {
          target.defaultValue = item.defaultValue.split(',');
        }
      }
    });
    programSettings.value = programSettings.value.slice();
  });
  const { mutate: changeProperty } = useMutation(schema.updateSchemaProperty);

  const schemaMonitorProfileProperties = useResult(
    schemaMonitorProfile.result,
    [],
    data => data.schemata[0].schemaProperties
  );

  const languages = [
    { id: 1, text: 'English', value: 'en' },
    { id: 2, text: 'Russian', value: 'ru', disabled: true }
  ];
  const language = ref({ id: 1, text: 'English', value: 'en' });

  const centerPosition = ref(['', '']);

  const maps = ref(baseLayersDefinition.filter(map => map.id !== 'empty'));

  const programSettings = ref([
    {
      key: 'programDefaultPosition',
      defaultValue: [-0.12766, 51.507351]
    },
    {
      key: 'programDefaultScale',
      defaultValue: 4
    },
    {
      key: 'programLogo',
      defaultValue: ''
    },
    {
      key: 'programTitle',
      defaultValue: ''
    },
    {
      key: 'programAvailableMaps',
      defaultValue: {
        osm: true,
        google_satellite: true
      }
    }
  ]);

  watch(
    () => programSettings.value,
    () => {
      const title = getById('programTitle').defaultValue;
      if (title) {
        document.title = title;
      }
    },
    { deep: true }
  );

  const handleCurrent = () => {
    handleGetCenter();
    handleGetZoom();
  };

  const close = () => {
    context.emit('close');
  };

  const zoomIn = () => {
    mapZoomIn();
    getById('programDefaultScale').defaultValue = getZoom();
  };
  const zoomOut = () => {
    mapZoomOut();
    getById('programDefaultScale').defaultValue = getZoom();
  };

  const saveHandler = () => {
    close();
  };

  const getById = key => {
    return programSettings.value.find(property => property.key === key);
  };

  const updateSettings = () => {
    let promises = [];

    programSettings.value.forEach(item => {
      let propertyId = schemaMonitorProfileProperties.value.find(
        itemInner => itemInner.key === item.key
      ).id;

      if (item.key === 'programDefaultPosition') {
        item.defaultValue = item.defaultValue.join(',');
      }

      if (item.key === 'programAvailableMaps') {
        // override default value for instantiated objects
        promises.push(
          schemasService.updateValues(
            [
              {
                id: propertyId,
                value: item.defaultValue
              }
            ],
            schemaMonitorProfile.result.value.schemata[0]?.id
          )
        );
      } else {
        promises.push(
          changeProperty({
            input: {
              id: propertyId,
              patch: {
                defaultValue: item.defaultValue
              }
            }
          })
        );
      }
    });

    Promise.allSettled(promises).then(() => {
      schemaMonitorProfile.refetch();
      close();
    });
  };

  const handleGetCenter = () => {
    let coords = getCenter();
    centerPosition.value = coords;
    getById('programDefaultPosition').defaultValue = coords.map(item =>
      item.toFixed(6)
    );
  };

  const handleAvailableMaps = (mapId, value) => {
    getById('programAvailableMaps').defaultValue = {
      ...getById('programAvailableMaps').defaultValue,
      [mapId]: value
    };
  };

  const handleGetZoom = () => {
    getById('programDefaultScale').defaultValue = getZoom();
  };

  const handleZoom = value => {
    setZoom(value);
  };

  const logoUrl = computed(() => {
    return mediaClient.getImageUrl(
      programSettings.value.find(property => property.key === 'programLogo')
        ?.defaultValue
    );
  });

  const handleChangeLogo = async e => {
    getById('programLogo').defaultValue = await imageService.upload(
      e.target.files[0]
    );
  };

  const handleClearLogo = () => {
    getById('programLogo').defaultValue = '';
  };

  onMounted(() => {
    schemaMonitorProfile.load();
  });

  return {
    maps,
    language,
    close,
    saveHandler,
    languages,
    centerPosition,
    zoomIn,
    zoomOut,
    schemaMonitorProfileProperties,
    programSettings,
    getById,
    updateSettings,
    handleGetCenter,
    handleZoom,
    handleGetZoom,
    handleCurrent,
    logoUrl,
    handleClearLogo,
    handleChangeLogo,
    handleAvailableMaps
  };
};

export default useGeneralSettings;
