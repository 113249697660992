<template>
  <v-navigation-drawer v-model="opened" app temporary>
    <v-list dense nav>
      <component
        :is="item.type"
        v-for="(item, index) in menuItems"
        :key="index"
        v-testid="item.id"
        :item="item"
        @click="itemHandler"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import MenuItemLogo from './MenuItemLogo';
import MenuItemDivider from './MenuItemDivider';
import MenuItemDefault from './MenuItemDefault';
import MenuItemGroup from './MenuItemGroup';
import { ref, computed } from '@vue/composition-api';
import useMenu from './useMenu';
import { usePopup } from '@/compositions/popup';

export default {
  name: 'AppNavigationDrawer',
  components: {
    MenuItemDivider,
    MenuItemLogo,
    MenuItemDefault,
    MenuItemGroup
  },
  setup(props, context) {
    const { menuItems, opened } = useMenu(context);
    const popup = usePopup();

    const itemHandler = item => {
      opened.value = false;
      if (typeof item?.action === 'function') {
        item.action({ popup });
      }
    };

    return {
      opened,
      menuItems,
      itemHandler
    };
  }
};
</script>
