import { computed, ref, watch } from '@vue/composition-api';

export const sortTypes = {
  default: 'default',
  abc: 'abc',
  boolean: 'boolean',
  number: 'number'
};

export const abcSort = (a, b) => {
  if (!a || !b) return 0;
  if (a.toLowerCase() < b.toLowerCase()) return -1;
  if (a.toLowerCase() > b.toLowerCase()) return 1;
  return 0;
};

const booleanSort = (a, b) => (a === b ? 0 : a ? -1 : 1);

const numberSort = (a, b) => a - b;

const sortFunctions = {
  [sortTypes.abc]: abcSort,
  [sortTypes.number]: numberSort,
  [sortTypes.boolean]: booleanSort
};

export function useSortBy(items, sortItems, storageKey) {
  const currentSort = ref(sortItems[0]);

  const getStorageKey = key => `sort${key}`;

  const currentSortId = computed(() => currentSort.value.id);

  watch(
    () => currentSortId.value,
    () => {
      if (storageKey) {
        localStorage.setItem(
          getStorageKey(storageKey),
          JSON.stringify(currentSortId.value)
        );
      }
    }
  );

  const sortedItems = computed(() => {
    if (currentSort.value.sortType === sortTypes.default) {
      return items.value;
    }

    if (!currentSort.value.sortKey) {
      console.warn("Sort key does't defined");
      return items.value;
    }

    if (!sortFunctions[currentSort.value.sortType]) {
      console.warn('Unknown sort type');
      return items.value;
    }

    return [...items.value].sort((a, b) =>
      sortFunctions[currentSort.value.sortType](
        a[currentSort.value.sortKey],
        b[currentSort.value.sortKey]
      )
    );
  });

  const handleSort = sortObject => {
    if (sortObject) {
      currentSort.value = sortObject;
    }
  };

  if (storageKey) {
    try {
      const sortId = JSON.parse(
        localStorage.getItem(getStorageKey(storageKey))
      );

      handleSort(sortItems.find(item => item.id === sortId));
    } catch {
      console.warn('can`t get sort key');
    }
  }

  return {
    sortedItems,
    handleSort,
    currentSortId
  };
}
