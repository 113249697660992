import { computed, inject, nextTick, provide, ref } from '@vue/composition-api';
import { useEventHook } from '../eventHook';

import Map from 'ol/Map';
import View from 'ol/View';

import {
  degToRad,
  transformCoordinates,
  transformCoordinatesToLonLat
} from './utils';
import { useBaseLayer } from './base-layer';
import { usePlans } from './plans';
import { useMarkers } from './markers';
import { useMapLandmarks } from './landmarks';
import { useMapGeozones } from './geozones';
import { useMapTrack } from '@/compositions/map/track';
import { useMapGeotags } from '@/compositions/map/geotags';

function createMap() {
  const onMapMount = useEventHook();
  const rotation = ref(0);
  const isReady = ref(false);
  const isMoving = ref(false);

  let map = null;

  const INITIAL_ZOOM = 4;
  const INITIAL_CENTER = [-0.12766, 51.507351];
  const defaultZoom = ref(INITIAL_ZOOM);
  const defaultCenter = ref(INITIAL_CENTER);
  const defaultRotation = ref(0);

  map = new Map({
    view: new View({
      center: transformCoordinates(defaultCenter.value),
      zoom: defaultZoom.value
    }),
    controls: []
  });

  map.getView().on('change:rotation', evt => {
    rotation.value = evt.target.get('rotation');
  });

  const {
    baseGroupLayer,
    selectBaseLayer,
    setOSMTileUrl,
    selectedBaseLayerId
  } = useBaseLayer();
  const { planGroupLayer, planEditGroupLayer, ...restPlan } = usePlans(map);

  const { landmarkLayer, ...restLandmark } = useMapLandmarks(map);
  const { geozoneLayer, ...restGeozone } = useMapGeozones(map);
  const { geotagLayer, basePointLayer, ...restGeotag } = useMapGeotags(map);
  const { markerLayer, ...restMarker } = useMarkers(map);
  const { trackLayer, infoLayer, ...restTrack } = useMapTrack(map);

  map.addLayer(baseGroupLayer);
  map.addLayer(planGroupLayer);
  map.addLayer(planEditGroupLayer);
  map.addLayer(landmarkLayer);
  map.addLayer(geozoneLayer);
  map.addLayer(geotagLayer);
  map.addLayer(basePointLayer);
  map.addLayer(trackLayer);
  map.addLayer(infoLayer);
  map.addLayer(markerLayer);

  map.on('movestart', () => {
    isMoving.value = true;
  });
  map.on('moveend', () => {
    isMoving.value = false;
  });

  const mount = async domElement => {
    map.setTarget(domElement);
    await nextTick();
    onMapMount.trigger();
    isReady.value = true;
  };

  const mapZoomIn = () => {
    const view = map.getView();
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 100
    });
  };

  const mapZoomOut = () => {
    const view = map.getView();
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 100
    });
  };

  const setZoom = zoom => {
    const view = map.getView();
    view.animate({
      zoom: zoom,
      duration: 100
    });
  };

  const getZoom = () => {
    const view = map.getView();
    return view.getZoom();
  };

  const setRotation = async value => {
    return new Promise(resolve => {
      const view = map.getView();
      view.animate(
        {
          rotation: degToRad(value),
          duration: 200
        },
        () => {
          resolve();
        }
      );
    });
  };

  const getCenter = () => {
    return transformCoordinatesToLonLat(map.getView().getCenter());
  };

  const rotationInDeg = computed(() => (rotation.value * 180) / Math.PI);

  const setDefaultCenter = (centerCoords, lazy = false) => {
    if (centerCoords) {
      defaultCenter.value = centerCoords;
    }
    if (!lazy) {
      map.getView().animate({
        center: transformCoordinates(defaultCenter.value),
        duration: 1000
      });
    }
  };

  const setDefaultZoom = (newZoom, lazy = false) => {
    if (newZoom) {
      defaultZoom.value = newZoom;
    }
    if (!lazy) {
      setZoom(defaultZoom.value);
    }
  };

  const setDefaultRotation = (newRotation, lazy = false) => {
    if (newRotation) {
      defaultRotation.value = newRotation;
    }
    if (!lazy) {
      setRotation(defaultRotation.value);
    }
  };

  return {
    map,
    rotationInDeg,
    setRotation,
    createMap,
    mapZoomIn,
    mapZoomOut,
    setZoom,
    getZoom,
    setDefaultCenter,
    setDefaultZoom,
    setDefaultRotation,
    selectBaseLayer,
    setOSMTileUrl,
    selectedBaseLayerId,
    mount,
    onMapMount,
    getCenter,
    plans: restPlan,
    landmarks: restLandmark,
    geozones: restGeozone,
    geotags: restGeotag,
    markers: restMarker,
    track: restTrack,
    isReady,
    isMoving
  };
}

export const MapProviderSymbol = Symbol('Map identifier');

export const useMapProvider = () => {
  provide(MapProviderSymbol, createMap());
};

export function useMap() {
  return inject(MapProviderSymbol);
}
